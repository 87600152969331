<template>
  <div class="full-width full-height">
    <router-view />
  </div>
</template>

<script>
import logo from '@/assets/corz.png';
export default {
  name: 'AuthHomePage',
  created() {
    this.$recaptchaInstance.showBadge();
  },
  data() {
    return {
      logo
    };
  }
};
</script>

<style></style>
